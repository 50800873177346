<template>
  <div class="page">
    <div class="globle_border">
      <back />
      <!-- echart -->
      <div v-show="dataEchart.length > 0" class="echart">
        <div class="echarts" id="main" style="width: 1600px; height: 400px;"></div>
      </div>
      <el-empty v-show="dataEchart.length == 0" description="暂无图表数据"></el-empty>

      <!-- 顶部搜索 -->
      <div class="search">
        <el-input v-model="productName" placeholder="请搜索商品名称" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <!-- <el-date-picker v-model="monthValue" type="month" placeholder="选择月" value-format="yyyy-MM"
          @change="handleSelect">
        </el-date-picker> -->
      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" height="400" @sort-change="handleSortChange">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <!-- <el-table-column show-overflow-tooltip prop="serialId" label="商品编号"></el-table-column> -->
          <el-table-column show-overflow-tooltip prop="barCode" label="商品条形码"></el-table-column>
          <el-table-column show-overflow-tooltip prop="productName" label="商品名称"></el-table-column>
          <el-table-column show-overflow-tooltip prop="factoryName" label="厂家"></el-table-column>
          <el-table-column show-overflow-tooltip prop="specs" label="规格"></el-table-column>
          <el-table-column show-overflow-tooltip prop="inventoryCount" label="库存数量"
            sortable="cunstom"></el-table-column>
          <el-table-column show-overflow-tooltip prop="inventoryAmount" label="库存金额(元)"
            sortable="cunstom"></el-table-column>
          <el-table-column show-overflow-tooltip prop="inventoryProportion" label="库存金额占比">
            <template slot-scope="scope">
              <div>{{ scope.row.inventoryProportion + '%' }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </div>

    <!-- 分页 -->
    <el-pagination class="pagination" background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>

  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  data() {
    return {
      shopId: '',
      productName: '', // 搜索商品名称
      // 表格数据
      tableData: [

      ],
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      // echart
      myChart: null,
      dataEchart: [],
      inventoryCount: '',
      inventoryAmount: ''
    }
  },
  created() {
    this.shopId = this.$route.query.shopId
    this.getShopListPage();
  },
  mounted() {
    // 注意调用顺序，先初始化echarts才给echarts赋值
    this.initEcharts()
    this.setEchartsOption()
  },

  methods: {
    // 获取列表
    getShopListPage() {
      let params = {
        productName: this.productName, // 搜索商品名称
        shopId: this.shopId, // shopId
        inventoryCountSort: this.inventoryCount,//库存数量排序
        inventoryAmountSort: this.inventoryAmount,//库存金额排序
        page: this.currentPage,
        pageSize: this.pageSize,
      };

      this.$axios.get(this.$api.shopListPage, { params })
        .then((res) => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list;
            this.totalItemsCount = res.data.result.totalCount;
          }

        })
        .catch(() => {

        });
    },
    handleSortChange({ prop, order }) {
      this.inventoryCount = ''
      this.inventoryAmount = ''
      // prop 是排序的列名，order 是排序方式，'ascending' 或 'descending'
      if (prop == 'inventoryCount') {
        this.inventoryCount = order === 'ascending' ? 'asc' : 'desc'
      }
      if (prop == 'inventoryAmount') {
        this.inventoryAmount = order === 'ascending' ? 'asc' : 'desc'
      }
      // 在这里你可以根据 prop 和 order 去后端请求排序后的数据，或者直接在前端数据上进行排序
      this.getShopListPage()
    },
    // 清空
    clearData() {
      this.tableData = [];
      this.currentPage = 1;
    },
    // 搜索
    onSearch() {
      this.clearData();
      this.getShopListPage();
    },
    // // 时间搜索
    // handleSelect(value) {
    //   this.clearData();
    //   this.monthValue = value;
    //   this.getShopListPage();
    // },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.clearData();
      this.pageSize = e;
      this.getShopListPage();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = [];
      this.currentPage = e;
      this.getShopListPage();
    },

    // 初始化echarts
    initEcharts() {
      var chartDom = document.getElementById('main');
      // 将创建的echarts示例放到vue的data中，这样在这个界面就想到于全局配置了
      this.myChart = echarts.init(chartDom);
    },
    // 配置echarts的option，展示数据图表
    setEchartsOption() {
      // 这里可以发送axios请求，然后通过响应的数据赋值给对应的x轴和y轴即可，由于这里没有于后端联调，所以简单请求判断一下，
      // 请求后端大概也是这个过程
      this.$axios.get(this.$api.shopPieChart + this.shopId).then((res) => {
        if (res.data.code == 100) {
          this.dataEchart = res.data.result
          this.dataEchart = (this.dataEchart || []).map(t => ({
            ...t,
            name: t.productName,
            value: t.inventoryProportion
          }))
          var option;
          option = {
            tooltip: {
              trigger: 'item',
              formatter: function (params) {
                var data = params.data;
                var tooltipText = `
                    <b>${data.productName}</b><br/>
                    库存数量： ${data.inventoryCount}<br/>
                    库存金额： ${data.inventoryAmount} 元<br/>
                    库存金额占比: ${data.inventoryProportion}%<br/>
                `;
                return tooltipText;
              }
            },
            legend: {
              orient: 'vertical',
              left: 'right'
            },
            series: [
              {
                name: '访问来源',
                type: 'pie',
                radius: '80%',
                data: this.dataEchart
              }
            ]
          };

          option && this.myChart.setOption(option);
        }
      })
    },
  }

}



</script>

<style scoped lang="scss">
.page {

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 250px;
      margin-left: 20px;
    }

    /deep/ .el-date-editor {
      background-color: #f2f2f2;
      border: none;
      border-radius: 10px;
      margin-left: 20px;
    }

    /deep/.el-range-input {
      background-color: #f2f2f2;
    }
  }

  .pagination {
    padding-bottom: 30px;
  }
}
</style>
